@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Guntur&display=swap');
@font-face {font-family: "Cambria W02 Regular";
  src: url("/assets/fonts/758d40d7ca52e3a9bff2655c7ab5703c.eot"); /* IE9*/
  src: url("/assets/fonts/758d40d7ca52e3a9bff2655c7ab5703c.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("/assets/fonts/758d40d7ca52e3a9bff2655c7ab5703c.woff2") format("woff2"), /* chrome、firefox */
  url("/assets/fonts/758d40d7ca52e3a9bff2655c7ab5703c.woff") format("woff"), /* chrome、firefox */
  url("/assets/fonts/758d40d7ca52e3a9bff2655c7ab5703c.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("/assets/fonts/758d40d7ca52e3a9bff2655c7ab5703c.svg#Cambria W02 Regular") format("svg"); /* iOS 4.1- */
}
body {
  margin: 0;
  font-family: 'Cambria W02 Regular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,p ,.MuiChip-label{
    font-family: 'Cambria W02 Regular' !important;
}
.MuiInputBase-input {
    font-family: 'Cambria W02 Regular' !important;
}
*, *::before, *::after {
    box-sizing: inherit;
    font-family: 'Cambria W02 Regular' !important;
}
.material-icons {
    font-family: 'Material Icons' !important;
}
code {
  font-family: 'Cambria W02 Regular';
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
  // color: rgba(255, 162, 0, 0.842);
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadada; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}