#root,
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

div,
a {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  color: $text-body;
  // font-weight: 500;
}

.layout1,
.layout2,
.MuiPaper-root,
.MuiTableCell-body,
.matx-customizer {
  color: $text-body !important;
}

code {
  font-family: 'Style Script', cursive;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
}