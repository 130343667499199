.inbox {
  .inbox__topbar {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    button {
      color: white !important;
    }
  }
}

.ql-container {
  min-height: 250px;
  // border-bottom-left-radius: 0.5em;
  // border-bottom-right-radius: 0.5em;
  // background: #fefcfc;
  p,
  code {
    font-size: 16px;
  }
}

.ql-toolbar {
  background: white;
  // background: #eaecec;
  // border-top-left-radius: 0.5em;
  // border-top-right-radius: 0.5em;
  border-bottom: none;
}
#print-area {
  // /position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // height: 100%;
  * {
    visibility: visible;
  }
}
.MuiListItemText-secondary{
  width: auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.danger_button{
  background-color: rgb(220, 0, 78) !important;
}
.danger_button:hover{
  background-color: rgb(190, 5, 70) !important;
}
.success_button {
  background-color: #08ad6c !important;
}
.success_button:hover {
  background-color: #068d57 !important;
}

@media only screen and (max-width: 40em) {
    
  td,
  th {
      display: block !important;
  }
  td[data-th]:before {
      content: attr(data-th);
  }
}

.appBarScroll{
  background-color:#02b0f5 !important;
  height: 67px;
  color:black !important;
 
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}
.react_player{
  border-radius: 12px;
}
